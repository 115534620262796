import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
//import settingsReducer from './slices/settings';
import SnackbarReducer from './slices/snackbar';
import clientInfoReducer from './ClientManagement/clientManagementSlice';
import userInfoReducer from './UserManagement/userManagementSlice';
import moduleInfoReducer from './ScreenBuilderManagement/screenBuilderManagementSlice';
import roleInfoReducer from './RoleManagement/roleManagementSlice';
import authInfoReducer from './AuthManagement/authManagementSlice';
import siteInfoReducer from './SiteManagement/siteManagementSlice';
import themInfoReducer from './ThemeManagement/ThemeManagementSlice'
import reportInfoReducer from './ReportManagement/reportManagementSlice';
import userProfileSlice from './UserProfile/userProfileSlice';
import dashboardReducer from './Dashboard/dashboardSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const rootReducer = combineReducers({
  clients: clientInfoReducer,
  users: userInfoReducer,
  roles: roleInfoReducer,
  sites: siteInfoReducer,
  SnackbarReducer: SnackbarReducer,
  screenBuilder: moduleInfoReducer,
  sites: siteInfoReducer,
  auth: authInfoReducer,
  theme: themInfoReducer,
  profile : userProfileSlice,
  report: reportInfoReducer,
  dashboard: dashboardReducer,
});

export { rootPersistConfig, rootReducer };