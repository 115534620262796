import React from 'react';
import { Redirect } from 'react-router-dom';

import utilitiesRoutes from './views/utilities/UtilitiesRoutes';

import materialRoutes from './views/material-kit/MaterialRoutes';
import filterViewRoutes from './views/FilterView/FilterViewRoutes';
import clientManagmentRoutes from './views/ClientManagment/ClientManagmentRoutes';
import userManagementRoutes from './views/UserManagement/UserManagementRoutes';
import ScreenBuilderManagementRoutes from './views/ScreenBuilderManagement/ScreenBuilderManagementRoutes';
import dashboardRoutes from './views/Dashboard/DashboardRoutes';
import roleManagmentRoutes from './views/RoleManagement/RoleManagementRoutes';
import siteManagmentRoutes from './views/SiteManagement/SiteManagementRoutes';
import themeManagmentRoutes from './views/ThemeManagement/ThemeManagementRoutes';
import userProfileManagmentRoutes from './views/UserProfile/UserProfileRoutes';
import reportManagmentRoutes from './views/ReportManagement/ReportManagemetRoutes';
const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />,
    },
    {
        path: '/ClientManagment',
        exact: true,
        component: () => <Redirect to="/ClientManagment" />,
    },
    {
        path: '/UserManagment',
        exact: true,
        component: () => <Redirect to="/UserManagment" />,
    },
    {
        path: '/ScreenBuilderManagement',
        exact: true,
        component: () => <Redirect to="/ScreenBuilderManagement" />,
    }, {
        path: '/RoleManagement',
        exact: true,
        component: () => <Redirect to="/RoleManagement" />
    }, {
        path: '/SiteManagement',
        exact: true,
        component: () => <Redirect to="/SiteManagement" />
    },
    {
        path: '/ThemeManagement',
        exact: true,
        component: () => <Redirect to="/ThemeManagement" />
    },
    {
        path: '/UserProfile',
        exact: true,
        component: () => <Redirect to="/UserProfile" />
    },
    {
        path: '/ReportManagement',
        exact: true,
        component: () => <Redirect to="/ReportManagement" />
    }
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...dashboardRoutes,
    ...filterViewRoutes,
    ...clientManagmentRoutes, 
    ...userManagementRoutes,
    ...ScreenBuilderManagementRoutes,
    ...roleManagmentRoutes,
    ...siteManagmentRoutes,
    ...themeManagmentRoutes,
    ...userProfileManagmentRoutes,
    ...reportManagmentRoutes,
    ...materialRoutes,
    ...utilitiesRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes
