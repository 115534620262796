import { xhrClient } from 'app/views/utilities/DataRequest'
import { config } from 'config.js'

const { isProd } = config
const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint
const APIVERSION = 'api/v1'

const getDashboardDataService = () => {
    return xhrClient.get(`${API_ENDPOINT}${APIVERSION}/dashboard/admin`)
}

const getStatisticsService = (clientId = '') => {
    const url = clientId
        ? `/dashboard/userstatistics?clientId=${clientId}`
        : '/dashboard/userstatistics'
    return xhrClient.get(`${API_ENDPOINT}${APIVERSION}${url}`)
}

const getUserProfilePhoto = (profileId) => {
    return xhrClient.get(
        `${API_ENDPOINT}${APIVERSION}/files/${profileId}`,
        null,
        null,
        'blob'
    )
}

export { getDashboardDataService, getStatisticsService, getUserProfilePhoto }
