import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import WaveLabsCssVars from './WaveLabsCssVars';
//import useSettings from 'app/hooks/useSettings';

// import cssVars from "css-vars-ponyfill";

const WaveLabsTheme = ({ children }) => {
   // const { settings } = useSettings()
   // let activeTheme = { ...settings.themes[settings.activeTheme] }
    //..ADD THEME
    const custom = createTheme({
        palette: {
            primary: {
                main: "#2C3E93"
            }
        },
        typography: {
            fontFamily: [
                'SF Pro Display',
                'sans-serif',
            ].join(','),
        }
    });
    // cssVars();
    // activeTheme.direction = settings.direction;
    return (
        <ThemeProvider theme={custom}>
            <CssBaseline />
            <WaveLabsCssVars> {children} </WaveLabsCssVars>
        </ThemeProvider>
    )
}

export default WaveLabsTheme
