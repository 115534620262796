import '../fake-db';
import React from 'react';
import { Provider } from 'react-redux';
import { Router, Switch, Route, BrowserRouter } from 'react-router-dom';
import AppContext from './contexts/AppContext';
import history from 'history.js';
import routes from './RootRoutes';
import { Store } from './redux/store';
import {
    GlobalCss,
    WaveLabsSuspense,
    WaveLabsTheme,
    WaveLabsLayout,
} from 'app/components';
import sessionRoutes from './views/sessions/SessionRoutes';
import AuthGuard from './auth/AuthGuard';
import { AuthProvider } from 'app/contexts/JWTAuthContext';
import { SettingsProvider } from 'app/contexts/SettingsContext';
import { SnackbarProvider } from 'notistack';
import { SuccessSnackbar, ErrorSnackbar } from './components/WaveLabsSnackbar/WaveLabsSnackbar';

const App = () => {
    return (
        <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
                <SuccessSnackbar />
                <ErrorSnackbar />
                <SnackbarProvider>
                    <SettingsProvider>
                        <WaveLabsTheme>
                            <GlobalCss />
                            <BrowserRouter basename={process.env.PUBLIC_URL}>
                                <Router history={history}>
                                    <AuthProvider>
                                        <WaveLabsSuspense>
                                            <Switch>
                                                {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                                {sessionRoutes.map((item, i) => (
                                                    <Route
                                                        key={i}
                                                        path={item.path}
                                                        component={item.component}
                                                        name="Nihal"
                                                    />
                                                ))}
                                                {/* AUTH PROTECTED DASHBOARD PAGES */}
                                                <AuthGuard>
                                                    <WaveLabsLayout />{' '}
                                                    {/* RETURNS <Layout1/> component */}
                                                </AuthGuard>
                                            </Switch>
                                        </WaveLabsSuspense>
                                    </AuthProvider>
                                </Router>
                            </BrowserRouter>
                        </WaveLabsTheme>
                    </SettingsProvider>
                </SnackbarProvider>
            </Provider>
        </AppContext.Provider>
    )
}

export default App
