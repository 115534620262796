import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Icon } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import WaveLabsIconButtons from '../WaveLabsIconButtons/WaveLabsIconButtons'

function WaveLabsHeaderActionList(props) {
    const { title, iconsList, length } = props
    const useStyles = makeStyles((theme) => ({
        root: {
            height: '65px',
            '& .MuiBreadcrumbs-separator': {
                fontSize: "2.3rem"
            }
        },
        icon: {
            fontSize: "2rem"
        }
    }))
    const classes = useStyles();
    return (
        <div className={`analytics mx-6 mb-2 ${classes.root}`}>
            <Grid
                container
                spacing={2}
                className="justify-between flex items-center"
                data-testid="header-Action-Div"
            >

                <Grid className="flex align-middle">
                    <Grid item className="pt-2">
                        <Breadcrumbs>
                            <Link to="/">
                                <Icon className={`material-icons-two-tone mt-2 ${classes.icon}`}>
                                    home
                                </Icon>
                            </Link>

                            <h1 data-testid="title" className="text-black mt-3" title={title}>{title}</h1>

                        </Breadcrumbs>

                    </Grid>
                </Grid>
                <Grid className="mt-5">
                    {iconsList &&
                        iconsList.map((ic, index) => {
                            return (
                                <WaveLabsIconButtons
                                    key={index}
                                    iconType={ic.iconType}
                                    tooltipTitle={ic.tooltipTitle}
                                    areaLabel={ic.areaLabel}
                                    iconComponent={ic.iconComponent}
                                    iconClickHandler={ic.iconClickHandler}
                                    filterPopupOpen={ic.filterPopupOpen}
                                    reference={ic.ref}
                                    length={length}
                                />
                            )
                        })}
                </Grid>
            </Grid>
        </div>
    )
}

WaveLabsHeaderActionList.propTypes = {
    title: PropTypes.string.isRequired,
    iconsList: PropTypes.array.isRequired,
}

WaveLabsHeaderActionList.defaultProps = {}

export default WaveLabsHeaderActionList
