import React from 'react';
import { WaveLabsLayouts } from './index';
import { WaveLabsSuspense } from 'app/components';
import useSettings from 'app/hooks/useSettings';

const WaveLabsLayout = (props) => {
    const { settings } = useSettings()
    const Layout = WaveLabsLayouts[settings.activeLayout]

    return (
        <WaveLabsSuspense>
            <Layout {...props} />
        </WaveLabsSuspense>
    )
}

export default WaveLabsLayout
