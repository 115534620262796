import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
    setDashboardData,
    setStatistics,
} from './dashboardSlice'
import { SNACKBAR_ERROR } from './../slices/snackbar'
import {
    getDashboardDataService,
    getStatisticsService,
} from './dashboardService'

function* getDashboardData() {
    try {
        const res = yield call(getDashboardDataService)
        yield put(setDashboardData(res))
    } catch (error) {
        yield put(SNACKBAR_ERROR(error.message))
    }
}

function* getStatistics(action) {
    try {
        const res = yield call(getStatisticsService, action.payload)
        yield put(setStatistics(res))
    } catch (error) {
        yield put(SNACKBAR_ERROR(error.message))
    }
}

function* watchGetDashboardData() {
    yield takeEvery('getDashboardDataAction', getDashboardData)
}

function* watchGetStatisticsData() {
    yield takeEvery('getStatisticsAction', getStatistics)
}

// Actions
export default function* dashboardSaga() {
    yield all([watchGetDashboardData(), watchGetStatisticsData()])
}
